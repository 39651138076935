export enum RatingType {
    STANDARD = "standard",
    FLOW = "flow",
    FOCUS = "focus"
}

// TO DO: enable import outside src/ 
export enum QuestionType {
    SLIDER = 'slider',
    INTERIM = 'interim'
}

  export enum QuestionAnimation {
    BIRD = 'bird', //slider
    BRAIN = 'brain', //slider
    CLOCK = 'clock', //slider
    WEATHER = 'weather', //slider
    PYRAMID = 'pyramid', //slider
    THUMB = 'thumb', //slider
    TREES = 'trees', //slider
    ROBOT = 'robot', //slider
    PEOPLE = 'people', //slider
    FLOWER = 'flower', //slider
    TULIPS = 'tulips', //slider
    MOUNTAIN = 'mountain', //slider
    LEGO = 'lego', //slider
    SPEECH_BUBBLE = 'speech_bubble', //slider
    HELP = 'help', //slider
    INCLUDE = 'include', //slider
    MASK = 'mask', //slider
    ROUTES = 'routes', //slider
    HAND = 'hand', //slider
    WORRIES = 'worries', //slider
    STRENGTHS = 'strengths', //slider
    CARE = 'care', //slider
    ANT = 'ant', //slider
    COMFORT = 'comfort',
    CRITIC = 'critic',
    BATTERY = 'battery',
    COLLAB = 'collab',
    ACCOUNTABILITY = 'accountability',
    HEAD = 'head',
    ICEBERG = 'iceberg',
    EMPTYSCALES = 'emptyscales',
    SCALES = 'scales',
    FLASK = 'flask',
    SPEEDOMETER = 'speedometer',
    STAIRS = 'stairs',
    NEBULA = 'nebula'
}

export interface SearchAndFilterProps {
    searchTerm: string;
    dimensionFilter: string | null;
    deprecatedFilter: boolean | null;
    handleSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleDimensionChange: (event: React.ChangeEvent<{}>, value: string | null) => void;
    handleDeprecatedChange: (event: React.ChangeEvent<{}>, value: { label: string; value: boolean } | null) => void;
}