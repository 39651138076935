import * as React from "react";
import { Create, useNotify, useRedirect } from 'react-admin';
import { QuestionForm } from "../../components/questions/QuestionForm";

export const CreateQuestion = (props) => {
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data) => {
        notify(`New question with id ${data.id} was saved`);
        redirect('edit', 'questions', data.id)
    }

    return (
        <Create {...props} mutationOptions={{ onSuccess }}>
            <QuestionForm formType={'create'}/>
        </Create>
    )
};