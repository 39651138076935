export const DimensionTypes = {
  de: ['Zugehörigkeitsgefühl', 'Health factor', 'Flow', 'Sicherheit', 'Der Joker'],
  en: ['Belonging', 'Health factor', 'Flow', 'Psychological Safety', 'Joker']
}

export const DimensionTranslations = {
    'Belonging': 'Zugehörigkeitsgefühl',
    'Health factor': 'Health factor',
    'Flow': 'Flow',
    'Psychological Safety': 'Sicherheit',
    'Joker': 'Der Joker'
}

export const DimensionAspect = {
  de: [
    'Inklusion',
    'Unterstützung',
    'Wertschätzung',
    'Selbstaktualisierung',
    'Resilienz',
    'Identität',
    'Gemeinsamer Purpose',
    'Verwundbarkeit',
    'Hilfe',
    'Fehler',
    'Speaking up',
    'Lernen',
    'Autonomie',
    'Konflikt',
    'Risiko eingehen',
    'Risiko',
    'Klare Ziele',
    'Direktes Feedback',
    'Motivation',
    'Gefühl der Kontrolle',
    'Zeitgefühl',
    'Fokus',
    'Stärken',
    'Vertiefung'
  ],
  en: [
    'Inclusion',
    'Support',
    'Appreciation',
    'Self-actualisation',
    'Resilience',
    'Identity',
    'Common purpose',
    'Vulnerability',
    'Help',
    'Mistakes',
    'Speaking up',
    'Learning',
    'Autonomy',
    'Conflict',
    'Risk taking',
    'Risk',
    'Clear goals',
    'Direct feedback',
    'Motivation',
    'Sense of control',
    'Sense of time',
    'Focus',
    'Strengths',
    'Deep involvement'
  ]
}

export const DimensionAspectTranslations = {
    'Inclusion': 'Inklusion',
    'Support': 'Unterstützung',
    'Appreciation': 'Wertschätzung',
    'Self-actualisation': 'Selbstaktualisierung',
    'Resilience': 'Resilienz',
    'Identity': 'Identität',
    'Common purpose': 'Gemeinsamer Purpose',
    'Vulnerability': 'Verwundbarkeit',
    'Help': 'Hilfe',
    'Mistakes': 'Fehler',
    'Speaking up': 'Speaking up',
    'Learning': 'Lernen',
    'Autonomy': 'Autonomie',
    'Conflict': 'Konflikt',
    'Risk taking': 'Risiko eingehen',
    'Risk': 'Risiko',
    'Clear goals': 'Klare ziele',
    'Direct feedback': 'Direktes Feedback',
    'Motivation': 'Motivation',
    'Sense of control': 'Gefühl der Kontrolle',
    'Sense of time': 'Zeitgefühl',
    'Focus': 'Fokus',
    'Strengths': 'Stärken',
    'Deep involvement': 'Vertiefung'
}

export const commonTextFields = ['animation', 'type', 'scale', 'revision'];

export const translationFields = [
  {'Question DE': 'question.de'},
  {'Question EN': 'question.en'}, 
  {'Phycological Dimension EN':'dimTransl.en'}, 
  {'Phycological Dimension DE':'dimTransl.de'},
  {'Dimension Aspect EN': 'aspTransl.en'},
  {'Dimension Aspect DE':'aspTransl.de'}
];

export enum PsycologicalDimensionFields {
    DimensionAspect = 'aspect',
    Dimension = 'dimension',
    DimensionTranslation = 'dimTransl',
    DimensionAspectTranslation = 'aspTransl'
}

export const DeprecatedOptions = [
  { label: 'Deprecated', value: true },
  { label: 'Not Deprecated', value: false },
]