import * as React from "react";
import { SimpleForm, TextInput, TranslatableFields, required } from 'react-admin';
import { Typography, Grid, Box } from '@mui/material';
import { BottomToolbar } from "../BottomToolbar";
import { CustomAutoComplete } from "../CustomAutocomplete";
import { ReferenceInput } from "./ReferenceInput";
import {
	RichTextInput,
	RichTextInputToolbar,
	LevelSelect,
	FormatButtons,
	ListButtons,
	LinkButtons,
	QuoteButtons,
	ClearButtons,
} from 'ra-input-rich-text';
import {
    DimensionTypes,
    DimensionTranslations,
    DimensionAspect,
    DimensionAspectTranslations 
} from '../../constants/questionDetails';
import { RatingType, QuestionAnimation, QuestionType} from '../../constants/questionTypes';
import "../../pages/questions/Questions.css"

interface QuestionsForm {
    formType: 'create' | 'edit'
    saveWithConfirm?: boolean
    confirmText?: string,
}

const questionsDetails = [
    {title: 'Animation', optionList: Object.values(QuestionAnimation)},
    {title: 'Type', optionList: [QuestionType.SLIDER]},
    {title: 'Scale', optionList: Object.values(RatingType)}
]

export const QuestionForm = ({formType, saveWithConfirm=false, confirmText, ...props}: QuestionsForm) => {
    return (
        <SimpleForm sanitizeEmptyValues={true} toolbar={<BottomToolbar form='questions' saveWithConfirm={saveWithConfirm} confirmText={confirmText}/>}>
                <Typography variant='h6' style={{ marginBottom: '4px', fontWeight: 'bold' }}>{'Question'}</Typography>
                <TranslatableFields locales={['de', 'en']} className="localizationFields" defaultLocale="de">
                    <TextInput source="question" label='' multiline resettable validate={required("Question is required")} className="localizationFields"/>
                </TranslatableFields>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Box sx={{ marginBottom: '20px' }}>
                            <Typography variant='h6' style={{ marginBottom: '4px', fontWeight: 'bold' }}>{'Psychological Dimension EN'}</Typography>
                            <CustomAutoComplete optionsList={DimensionTypes.en} name={'dimTransl.en'} styleName="questionDetailsSelector"/>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ marginBottom: '20px' }}>
                            <Typography variant='h6' style={{ marginBottom: '4px', fontWeight: 'bold' }}>{'Psychological Dimension DE'}</Typography>
                            <ReferenceInput watchFieldName={"dimTransl.en"} optionsList={DimensionTypes.de} fieldName={'dimTransl.de'} translationMap={DimensionTranslations}/>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <Box sx={{ marginBottom: '20px' }}>
                            <Typography variant='h6' style={{ marginBottom: '4px', fontWeight: 'bold' }}>{'Psychological Dimension Aspect EN'}</Typography>
                            <CustomAutoComplete optionsList={DimensionAspect.en} name={'aspTransl.en'} styleName="questionDetailsSelector"/>
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ marginBottom: '20px' }}>
                            <Typography variant='h6' style={{ marginBottom: '4px', fontWeight: 'bold' }}>{'Psychological Dimension Aspect DE'}</Typography>
                            <ReferenceInput
                                watchFieldName={"aspTransl.en"}
                                optionsList={DimensionAspect.de}
                                fieldName={'aspTransl.de'}
                                translationMap={DimensionAspectTranslations}
                                />
                        </Box>
                    </Grid>
                </Grid>
                <Grid container>
                    {questionsDetails.map((questionDetails) => {
                        return <Grid item xs={4} key={questionDetails.title}>
                                    <Box sx={{ marginBottom: '20px' }}>
                                        <Typography variant='h6' style={{ marginBottom: '4px', fontWeight: 'bold' }}>{questionDetails.title}</Typography>
                                        <CustomAutoComplete optionsList={questionDetails.optionList} name={questionDetails.title.toLocaleLowerCase()} styleName="questionDetailsSelector"/>
                                    </Box>
                                </Grid>
                        })
                    }
                </Grid>
                <Typography variant='h6' style={{ marginBottom: '4px', fontWeight: 'bold' }}>{'Content for backside of cards'}</Typography>
                <TranslatableFields locales={['de', 'en']} className="backsideContentTabs" defaultLocale="de">
                <RichTextInput
                    toolbar={
                        <RichTextInputToolbar>
                            <LevelSelect />
                            <FormatButtons />
                            <ListButtons />
                            <LinkButtons />
                            <QuoteButtons  />
                            <ClearButtons />
                        </RichTextInputToolbar>
                    }
                    source="backside"
                    />
                </TranslatableFields>
            </SimpleForm>
    )
}