import { fetchUtils } from 'react-admin';
import {
    removeEmptyValues,
    transformBacksideBeforeSave,
    transformBacksideBeforeShow,
    createDimensionTypeForQ,
    cleanSetData,
} from '../helpers/transformers';
import authProvider from './authProvider';

const apiUrl = process.env.REACT_APP_BASE_URL

const httpClient = (
    url: string,
    options: any = {}) => {
    const token = authProvider.getUserDetails();
    if (!options.headers) {
        options.headers = new Headers({ Accept: 'application/json' });
    }
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
}


const dataProvider = {
    getList: async (resource, params) => {
        const options: any = {}
        options.method = 'GET';
        if (resource === 'questions') {
            let url = `${apiUrl}/questions`;
            if(params.filter.type) url = `${apiUrl}/questions?questionsType=${params.filter.type}`
            const res = await httpClient(url, options);

            return Promise.resolve({
                data: res.json.map(question => ({ ...question, id: question.questionId ||  question.interimType})),
                total: res.json.length
            })
        } else if (resource === 'surveySets') { 
            const url = `${apiUrl}/surveysets`;
            const res = await httpClient(url, options);
            return Promise.resolve({
                data: res.json.map(set => ({ ...set, id: `${set.setId}#${set.scopeId}` })),
                total: res.json.length
            })
        }

    },
    getOne: async (resource, params) => {
        const options: any = {}
        options.method = 'GET';
        if (resource === 'questionsSetsMapping') {
            const url = `${apiUrl}/questions/${params.id}?surveySetMap=true`;
            const res = await httpClient(url, options);
            return Promise.resolve({
                data: { ...res.json, id: res.json ? res.json.questionId : params.id },
            })

        } else if (resource === 'questions') {
            const url = `${apiUrl}/questions/${params.id}`;
            const res = await httpClient(url, options);
            return Promise.resolve({
                data: {
                    ...res.json,
                    id: res.json.questionId,
                    //@ts-ignore
                    ...(res.json.backside && { backside: transformBacksideBeforeShow(res.json.backside) })
                }
            })
        } else if (resource === 'surveySets') {
            const url = `${apiUrl}/surveysets/${params.meta.setId}/${params.meta.scopeId}`;
            const res = await httpClient(url, options);
            return Promise.resolve({
                data: {
                    ...res.json,
                    id: params.id,
                }
            })
        }

    },
    create: async (resource, params) => {
        if (resource === 'questions') {
            const url = `${apiUrl}/questions`;
            const reqBody = removeEmptyValues(Object.assign({}, params.data));
            createDimensionTypeForQ(reqBody);
            if (reqBody.backside) reqBody.backside = transformBacksideBeforeSave(reqBody.backside);
            const options = {
                method: 'POST',
                body: JSON.stringify(reqBody),
            };
            const res = await httpClient(url, options);
            return Promise.resolve({
                data: res.json
            })
        }
        if (resource === 'surveySets') {
            const url = `${apiUrl}/surveysets`;
            const { questions, ...result } = params.data;
            result.questions = questions.map(questionArr => questionArr.questionId)
            const data = cleanSetData(result)
            const options = {
                method: 'POST',
                body: JSON.stringify(data),
            };
            const res = await httpClient(url, options);
            return Promise.resolve({
                data: res.json
            })
        }
    },
    update: async (resource, params) => {
        if (resource === 'questions') {
            const url = `${apiUrl}/questions/${params.id}`;
            const reqBody = removeEmptyValues(Object.assign({}, {
                ...params.data,
                revision: params.data.revision + 1
            }));
            delete reqBody['id'];
            if (reqBody.backside) reqBody.backside = transformBacksideBeforeSave(reqBody.backside)
            createDimensionTypeForQ(reqBody);
            const options = {
                method: 'PUT',
                body: JSON.stringify(reqBody),
            };
            const res = await httpClient(url, options);
            return Promise.resolve({
                data: res.json
            })
        }
        if (resource === 'surveySets') {
            const { questions, id, ...result } = params.data;

            const previousQuestions = params.previousData.questions.map(questionArr => questionArr.questionId)
            const currentQuestions = questions.map(questionArr => questionArr.questionId)

            result.questions = currentQuestions
            const data = cleanSetData(result)

            const addedQuestions = currentQuestions.filter(element => !previousQuestions.includes(element));
            const removedQuestions = previousQuestions.filter(element => !currentQuestions.includes(element));
            
            const options = {
                method: 'PUT',
                body: JSON.stringify({ ...data, removedQuestions, addedQuestions }),
            };
            const url = `${apiUrl}/surveysets/${params.setId}`;

            const res = await httpClient(url, options);
            return Promise.resolve({
                data: res.json
            })
        }
        return Promise.resolve()
    },
    updateMany: async (resource, params) => {
        const options: any = {}
        const { questionDetails, ...attribute } = params.data
        options.body = JSON.stringify({ questionDetails, attribute });     
        options.method = 'PATCH';
        const url = `${apiUrl}/questions`;
        const res = await httpClient(url, options);
        return Promise.resolve({
            data: res.json
        })
    },
    getQuestionsByDimension: async (resource, params) => {
        const options: any = {}
        options.method = 'GET';
        const dimension = params.filter.dimension
        const url = `${apiUrl}/questions?dimension=${dimension}`;
 
        const res = await httpClient(url, options);
        return Promise.resolve({ data: res.json })
    },
    delete: async (resource, params) => {
        // not in use currently
        return Promise.resolve()
    },
    deleteMany: async (resource, params) => {
        if (resource === 'questions') {
            const options: any = {}
            options.method = 'DELETE';
            const ids = params.ids.join(',');
            const url = `${apiUrl}/questions?ids=${ids}`;
            const res = await httpClient(url, options);
            return Promise.resolve({
                data: res.json
            })
        }
        if (resource === 'surveySets') {
            const url = `${apiUrl}/surveysets`;
            const options = {
                method: 'DELETE',
                body: JSON.stringify({setIds : params.ids}),
            };
            const res = await httpClient(url, options);
            return Promise.resolve({
                data: res.json
            })
        }
    },
};

export default dataProvider