import { SaveButton, Toolbar, ListButton } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { SaveWithConfirm } from './questions/SaveConfirmButton';

export const BottomToolbar = ({form, saveWithConfirm, confirmText }) => {
    const navigate = useNavigate();

    return (
        <Toolbar>
        {!saveWithConfirm ? <SaveButton/> : <SaveWithConfirm form={form} content={confirmText}/>}
        <ListButton onClick={() => navigate(-1)} label="Back" icon={<ChevronLeftIcon />} sx={{position: 'absolute', right: '10px'}} />
        </Toolbar>
    )

}
