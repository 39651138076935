import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Grid, Typography, Box } from '@mui/material';
import { SimpleShowLayout, TextField, RichTextField, DateField, BooleanField } from 'react-admin';
import { translationFields, commonTextFields } from '../../constants/questionDetails';

export const ShowQuestionDetails = ({ isOpen, onClose, record }) => {
    return (
        <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
        <DialogTitle>&nbsp; Details for question {record.id}</DialogTitle>
        <DialogContent>
          <SimpleShowLayout record={record}>
          <Typography variant='h6' sx={{fontWeight: 'bold'}}>Question ID</Typography>
          <TextField source="id" label="" disabled sx={{fontWeight: 'bold'}}/>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              { translationFields.map((field, i) => {
                const [[title, source]] = Object.entries(field)
                return ( <Box sx={{ marginBottom: '20px' }} key={i}>
                <Typography>{title}</Typography>
                <TextField source={source}/>
                </Box> )
              })}
            </Grid>
            <Grid item xs={6}>
              { commonTextFields.map((field, i) => {
                  return ( <Box sx={{ marginBottom: '20px' }} key={i}>
                  <Typography sx={{ ":first-letter": {textTransform: "capitalize"}}}>{field}</Typography>
                  <TextField source={field}/>
                  </Box> )
                })}
              <Box sx={{ marginBottom: '20px' }}>
              <Typography>Deprecated</Typography>
              <BooleanField source='deprecated'/>
              </Box>
              <Box sx={{ marginBottom: '20px' }}>
              <Typography>Created At:</Typography>
              <DateField source='createdAt'/>
              </Box>
            </Grid>
          </Grid>
          <Typography>Backside DE</Typography>
          <RichTextField source="backside.de" label={''}/>
          <Typography>Backside EN</Typography>
          <RichTextField source="backside.en" label={''}/>
          </SimpleShowLayout>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
};


