import { useState } from 'react';
import { useRedirect, useNotify, useRecordContext, useDataProvider, Confirm } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { Button } from '@mui/material';

export const DuplicateButton = ({resource}) => {
    const [isOpen, setOpen] = useState(false);
    const record = useRecordContext();
    const redirect = useRedirect();
    const notify = useNotify();
    const dataProvider = useDataProvider();
    const { control } = useFormContext();
    const watchedFields = useWatch({ control });

    const handleClick = (e) => {
        e.preventDefault();
        setOpen(true);
    }

    const handleDialogClose = () => setOpen(false);

    const handleConfirm = () => {
        if (!record) return;

        const { id, setId, createdAt, updatedAt, ...rest } = watchedFields;
        dataProvider.create(resource, { data: rest })
            .then(() => {
                notify('Record duplicated successfully');
                redirect('list', resource);
            })
            .catch(error => {
                notify(`Error: ${error.message}`);
            })
            .finally(() => {
                setOpen(false);
            });
    };

    return (
        <>
        <Button onClick={handleClick} color="primary" variant="contained">
            Duplicate
        </Button>
        <Confirm
            isOpen={isOpen}
            title={`Duplicate record #${record && record.id}`}
            content="Are you sure you want to duplicate this record? Make sure scope is correct :)"
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
        />
        </>
    );
};
