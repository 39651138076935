import { useWatch } from "react-hook-form";
import { useEffect, useState, useRef } from 'react';
import { Autocomplete, TextField } from '@mui/material';
import { useController } from 'react-hook-form';

export const ReferenceInput = ({ watchFieldName, optionsList, fieldName, translationMap }) => {
    const field = useWatch({ name: watchFieldName });

    const {
        field: { ref, ...inputProps },
    } = useController({
        name: fieldName,
    });

    const [selectedTranslation, setSelectedTranslation] = useState(inputProps.value || null)
    const initialValueRef = useRef(field);

    useEffect(() => {
        if (field === initialValueRef.current) {
            return; 
        }
        const mappedValue = translationMap[field];
        if (mappedValue && inputProps.value !== mappedValue) {
            inputProps.onChange(mappedValue);
            const selectedOption = optionsList.find((option) => option === mappedValue)
            setSelectedTranslation(selectedOption)
        }
        initialValueRef.current = field;        
    }, [field, inputProps, translationMap, optionsList]);
 
    const handleAutocompleteChange = (_, value) => {
        const selectedOption = optionsList.find((option) => option === value);

        if (selectedOption) {
            setSelectedTranslation(selectedOption)
            inputProps.onChange(selectedOption);
        } else {
            inputProps.onChange(value);
        }
    };

    return (
        <Autocomplete
            id={fieldName}
            freeSolo
            className='questionDetailsSelector'
            onChange={handleAutocompleteChange}
            value={selectedTranslation || ''}
            options={optionsList}
            renderInput={(params) => <TextField  {...params} {...inputProps} label="Add or select value" />}
        />
    );
}

