import { useState, useEffect } from 'react';
import { useRecordContext } from 'react-admin'

const useQuestionsSelector = (selectedLanguage, dataProvider) => {
    const [options, setOptions] = useState({});
    const [optionsHistory, setOptionsHistory] = useState(() => new Map());
    const [selectedValues, setSelectedValues] = useState([]);
    const record = useRecordContext();

    useEffect(() => {
        const getInitialState = async (questions) => {
            const updates = await Promise.all(questions.map(async (question) => {
                const { data: questionData } = await dataProvider.getOne('questions', { id: question.questionId });
                const selectedQuestion = { id: questionData.id, name: questionData.question[selectedLanguage] };
                if(questionData.deprecated) {
                    //@ts-ignore
                    selectedQuestion.deprecated = true
                }

                setSelectedValues(prev => [...prev, selectedQuestion]);

                const { data } = await dataProvider.getQuestionsByDimension('questions', { filter: { dimension: questionData.dimension } });

                return {
                    dimensionKey: questionData.dimTransl[selectedLanguage],
                    newData: data,
                    deprecatedQuestion: questionData.deprecated ? { dimension: questionData.dimension, question: questionData.question, questionId: questionData.questionId, deprecated: true } : null
                };
            }));
            setOptionsHistory(prev => {
                const updatedMap = new Map(prev);
                updates.forEach(({ dimensionKey, newData, deprecatedQuestion }) => {
                    if (updatedMap.has(dimensionKey)) {
                        const existingData = updatedMap.get(dimensionKey);
                        if (deprecatedQuestion) {
                            const isDuplicate = existingData.some(item => item?.questionId === deprecatedQuestion.questionId);
                            if (!isDuplicate) {
                                updatedMap.set(dimensionKey, [...existingData, deprecatedQuestion]);
                            }
                        } 
                    } else {
                        updatedMap.set(dimensionKey, deprecatedQuestion ? [...newData, deprecatedQuestion] : newData);
                    }
                });
                return updatedMap;
            });
        };

        if (record && record.questions) {
            getInitialState(record.questions);
        }
    }, [record, dataProvider, selectedLanguage]);

    return { options, setOptions, optionsHistory, setOptionsHistory, selectedValues, setSelectedValues };
};

export default useQuestionsSelector;