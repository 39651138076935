//@ts-nocheck
import './App.css';
import React from 'react';
import { Admin, Resource, Layout } from 'react-admin';
import { QuestionsList } from './pages/questions/QuestionsList'
import { EditQuestion } from './pages/questions/EditQuestion';
import { CreateQuestion } from './pages/questions/CreateQuestion';
import dataProvider from './providers/dataProvider';
import authProvider from './providers/authProvider';
import { customTheme, darkTheme } from './theme';
import { AppMenu } from './components/AppMenu';
import {LoginPage} from './pages/LoginPage';
import { CreateSurveySet } from './pages/surveySets/CreateSurveySet';
import { SurveySetsList } from './pages/surveySets/SurveySetsList';
import { EditSurveySet } from './pages/surveySets/EditSurveySet';

function App() {
  if (!authProvider || !dataProvider) return null;

  return (
      <Admin
      authProvider={authProvider}
      //@ts-ignore 
      dataProvider={dataProvider}
      theme={customTheme}
      darkTheme={darkTheme}
      layout={(props) => <Layout {...props} menu={AppMenu} />}
      loginPage={LoginPage}
      requireAuth
      >
        
        <Resource name="questions" list={QuestionsList} edit={EditQuestion} create={CreateQuestion} hasEdit hasCreate/>
        <Resource name="surveySets" list={SurveySetsList} create={CreateSurveySet} edit={EditSurveySet} hasCreate hasEdit/>
      </Admin>
    
  );
}

export default App;
