import { useState } from 'react';
import { camelCase } from 'lodash';
import { Button, useRefresh, useNotify, useUnselectAll, useUpdateMany, useListContext, BulkDeleteButton } from 'react-admin';
import { Autocomplete, Select, MenuItem, FormControl, Grid, TextField, InputLabel } from '@mui/material';
import {
    DimensionAspect,
    DimensionAspectTranslations,
    DimensionTranslations,
    DimensionTypes,
    PsycologicalDimensionFields
} from '../../constants/questionDetails';

const fieldMap = {
    [PsycologicalDimensionFields.Dimension]: PsycologicalDimensionFields.DimensionTranslation,
    [PsycologicalDimensionFields.DimensionAspect]: PsycologicalDimensionFields.DimensionAspectTranslation
}
const optionsMap = {
    [PsycologicalDimensionFields.Dimension]: { options: DimensionTypes, translations: DimensionTranslations },
    [PsycologicalDimensionFields.DimensionAspect]: { options: DimensionAspect, translations: DimensionAspectTranslations }
}
export const BulkActionButtons = () => {
    const { selectedIds, data } = useListContext()
    const selectedRecords = data.filter(record => selectedIds.includes(record.id)).map(record => ({ questionId: record.id, version: record.version, revision: record.revision }))
    const refresh = useRefresh();
    const notify = useNotify();
    const unselectAll = useUnselectAll('questions');
    const [field, setField] = useState('');
    const [value, setValue] = useState('');
    const [valueDE, setValueDE] = useState('');
    const [updateMany, { isLoading }] = useUpdateMany();

    const handleFieldChange = (event) => {
        setField(event.target.value);
        setValue('')
        setValueDE('')
    }

    const handleInputChange = (newValue, options, translations) => {
        const selectedOption = options.find((option) => option === newValue);
        const value = selectedOption || newValue
        setValue(value as string)
        if (translations[value]) setValueDE(translations[value])
    }

    const handleInputDEChange = (newValue, options, translations) => {
        const selectedOption = options.find((option) => option === newValue);
        const value = selectedOption || newValue
        setValueDE(value as string)
        const indexOfValue = Object.values(translations).indexOf(value);
        const enValue = Object.keys(translations)[indexOfValue]
        if (enValue) setValue(enValue)
    }

    const handleClick = () => {
        const translationObjtName = fieldMap[field];
        updateMany('questions', {
            ids: selectedIds, data: {
                [field]: camelCase(value),
                [translationObjtName]: { en: value, de: valueDE },
                questionDetails: selectedRecords
            }
        })
            .then(() => {
                refresh();
                notify('Questions updated');
                setField('')
                setValue('')
                unselectAll();
            })
            .catch((error) => {
                setField('')
                setValue('')
                notify('Error: questions not updated', error)
            });
    };

    return (
        <Grid container alignItems="center" spacing={1} sx={{ marginTop: '5px' }}>
            <Grid item>
                <FormControl variant="standard" sx={{ minWidth: 100 }}>
                    <InputLabel>Select</InputLabel>
                    <Select
                        id="select-attribute"
                        value={field}
                        onChange={(event) => handleFieldChange(event)}
                    >
                        <MenuItem value={PsycologicalDimensionFields.Dimension}>Dimension</MenuItem>
                        <MenuItem value={PsycologicalDimensionFields.DimensionAspect}>Aspect</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            {field &&
                <>
                    <Grid item>
                        <Autocomplete
                            sx={{ minWidth: 200 }}
                            value={value}
                            freeSolo
                            onChange={(_, newValue) => handleInputChange(newValue, optionsMap[field]['options'].en, optionsMap[field].translations)}
                            onInputChange={(_, newValue) => handleInputChange(newValue, optionsMap[field]['options'].en, optionsMap[field].translations)}
                            options={optionsMap[field]['options'].en}
                            renderInput={(params) => <TextField  {...params} label="Add or select value EN" required/>}
                        />
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            sx={{ minWidth: 200 }}
                            value={valueDE}
                            freeSolo
                            onChange={(_, newValue) => handleInputDEChange(newValue, optionsMap[field]['options'].de, optionsMap[field].translations)}
                            onInputChange={(_, newValue) => handleInputDEChange(newValue, optionsMap[field]['options'].de, optionsMap[field].translations)}
                            options={optionsMap[field]['options'].de}
                            renderInput={(params) => <TextField  {...params} label="Add or select value DE" required/>}
                        />
                    </Grid>
                </>
            }
            <Grid item>
                <Button label="Update" onClick={handleClick} disabled={isLoading || !value || !valueDE} />
                <BulkDeleteButton />
            </Grid>
        </Grid>

    );
};