import { SelectArrayInput, ArrayInput, SimpleFormIterator, NumberInput, SelectInput } from 'react-admin';
import { Typography } from '@mui/material';
import { useGetInterims } from '../../hooks/useGetInterims';
import { Fragment } from 'react';

const sectionTypes = ['intro', 'outro']

export const InterimsSelector = ({ dataProvider, selectedLanguage }) => {
    const interims = useGetInterims(dataProvider, selectedLanguage);

    return (
        <>
            {sectionTypes.map(section => {
                return <Fragment key={section}>
                    <Typography>{`Select ${section} section for the survey set`}</Typography>
                    <SelectArrayInput source={section} choices={interims} label='' sx={{ width: '50%', marginBottom: '15px' }} />
                </Fragment>

            })}
            <Typography>{`Add sections after some questions`}</Typography>
            <ArrayInput source="section">
                <SimpleFormIterator inline>
                    <NumberInput source="position" helperText={'Add the position of the question after which you want to have an interim'} />
                    <SelectInput source="interim" choices={interims}/>
                </SimpleFormIterator>
            </ArrayInput>
        </>

    )
}