import { useState, useEffect } from 'react';

export const usePagination = (itemsPerPage: number, data) => {
    const [currentPage, setCurrentPage] = useState(1);
    const [paginatedData, setPaginatedData] = useState([]);
    const totalPages = Math.ceil(data?.length / itemsPerPage);

    useEffect(() => {
        if (data) {
            const startIndex = (currentPage - 1) * itemsPerPage;
            const endIndex = startIndex + itemsPerPage;
            const paginatedItems = data.slice(startIndex, endIndex);
            setPaginatedData(paginatedItems);
        }
    }, [currentPage, data, itemsPerPage]);

    const handlePageChange = (newPage: number) => {
        setCurrentPage(newPage);
    };

    return { paginatedData, totalPages, currentPage, handlePageChange };
};