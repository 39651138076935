import { radiantLightTheme, nanoLightTheme, radiantDarkTheme, nanoDarkTheme  } from 'react-admin';

const isProdEnvironment = process.env.REACT_APP_IS_PROD === "true"
const selectedTheme = isProdEnvironment ? nanoLightTheme : radiantLightTheme
export const darkTheme = isProdEnvironment ? nanoDarkTheme : radiantDarkTheme
export const customTheme = {
    ...selectedTheme,
    typography: {
      fontFamily: [
          'Larsseit-Regular',
          'sans-serif',
          'Larsseit-Bold',
          'sans-serif',
      ].join(','),
      fontSize: 14
  },
  }