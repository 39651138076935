
import { useCallback } from 'react';
import { useLogin, useSafeSetState } from 'react-admin';
import { useMutation, UseMutationResult } from 'react-query';
import { ErrorRequireNewPassword } from 'ra-auth-cognito'

export type LoginFormData = {
    username: string;
    password: string;
};

export type UseCognitoLoginResult<NewPasswordFormData = unknown> = [
    (values: LoginFormData | NewPasswordFormData) => Promise<unknown>,
    UseMutationResult<unknown, unknown, LoginFormData | NewPasswordFormData> & {
        requireNewPassword: boolean;
    }
];

export type NewPasswordFormData = {
    newPassword: string;
    confirmNewPassword: string;
  };

export const useCognitoLogin = ({
    redirectTo,
}: {
    redirectTo: string;
}): UseCognitoLoginResult<NewPasswordFormData> => {
    const login = useLogin();
    const [requireNewPassword, setRequireNewPassword] = useSafeSetState(false);

    const mutation = useMutation<
        unknown,
        unknown,
        LoginFormData | NewPasswordFormData
    >(values => login(values, redirectTo));

    const cognitoLogin = useCallback(
        (values: LoginFormData | NewPasswordFormData) => {
            return mutation.mutateAsync(values).catch(error => {
                if (error instanceof ErrorRequireNewPassword) {
                    setRequireNewPassword(true);
                    return;
                }

                throw error;
            });
        },
        [mutation, setRequireNewPassword]
    );

    return [cognitoLogin, { ...mutation, requireNewPassword }];
};