import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

export const SearchField = ({ searchTerm, handleSearch, label }) => {
    return (
        <TextField
            placeholder={label}
            onChange={handleSearch}
            value={searchTerm}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon />
                    </InputAdornment>
                ),
            }}
            variant='outlined'
            sx={{
                mb: 2,
                ml: '10px',
                width: 300,
                maxWidth: '100%'
            }}
        />)
}