import { useState, useEffect } from 'react'
import { List, Datagrid, TextField, EditButton, useListController } from 'react-admin';
import { TopToolbarActions } from '../../components/TopToolbar'
import { usePagination } from '../../hooks/usePaginations';
import { Pagination } from '../../components/Pagination';
import { SearchField } from '../../components/SearchField';

export const SurveySetsList = (props) => {
    const { data } = useListController();
    const [filteredData, setFilteredData] = useState([])
    const { paginatedData, totalPages, currentPage, handlePageChange} = usePagination(10, filteredData);
    const [searchTerm, setSearchTerm] = useState('')

    const handleSearch = event => {
        setSearchTerm(event.target.value)
        handlePageChange(1)
    }

    useEffect(() => {
        if (data) {
          let updatedData = [...data]
    
          if (searchTerm) {
            updatedData = updatedData.filter(item => {
              const scopeId = item.scopeId.toLowerCase();
              const name = item.name ? item.name.toLowerCase() : '';

              return (
                  scopeId.includes(searchTerm.toLowerCase()) ||
                  name.includes(searchTerm.toLowerCase())
              );
          });
          }
    
          setFilteredData(updatedData)
        }
      }, [data, searchTerm])
    return (
        <>
            <List {...props} actions={<TopToolbarActions />} pagination={<></>} resource='surveySets'>
            <SearchField searchTerm={searchTerm} handleSearch={handleSearch} label={"Search set"}/>
                <Datagrid data={paginatedData}>
                    <TextField source="setId" sortable={false}/>
                    <TextField source="scopeId" sortable={false}/>
                    <TextField source="name" sortable={false}/>
                    <EditButton label='Edit/Duplicate'/>
                </Datagrid>
                <Pagination
                    currentPage={currentPage} 
                    totalPages={totalPages} 
                    handlePageChange={handlePageChange} 
                />
            </List>
        </>
    )
}