import {  SelectInput, ArrayInput, SimpleFormIterator, FormDataConsumer } from 'react-admin';
import { DimensionTypes } from '../../constants/questionDetails';
import { camelCase } from 'lodash';
import { Typography, TextField } from '@mui/material';
import { ReOrderButtons } from './QuestionsReorderBtns';
import useQuestionsSelector from '../../hooks/useQuestionsSelector';

export const QuestionsSelector = ({ dataProvider, selectedLanguage }) => {
    const {
        options,
        setOptions,
        optionsHistory,
        setOptionsHistory,
        selectedValues,
        setSelectedValues,
    } = useQuestionsSelector(selectedLanguage, dataProvider)

    const handleDimensionChange = async (value, index) => {
        if(value) {
            if(optionsHistory.has(value)) {
                setOptions({[index]: optionsHistory[value]});
                return;
            } else {
                const { data } = await dataProvider.getQuestionsByDimension('questions', { filter: { dimension: camelCase(value) } });
                setOptions({[index]: data});
                setOptionsHistory(prev => new Map([...prev, [value, data]]));
            }
        }
    };
    const handleQuestionChange = (value, label, index, deprecated) => {
        const updatedSelectedValues = [...selectedValues];
        updatedSelectedValues[index] = { id: value, name: label, deprecated };
        if(deprecated) {
            updatedSelectedValues[index].deprecated = true
        }
        setSelectedValues(updatedSelectedValues);
    };

    const handleReorder = (oldIndex, newIndex) => {
        setSelectedValues(prevSelectedValues => {
            const updatedSelectedValues = [...prevSelectedValues];
            const [movedItem] = updatedSelectedValues.splice(oldIndex, 1);
            updatedSelectedValues.splice(newIndex, 0, movedItem);
            return updatedSelectedValues;
        });

        setOptions(prevOptions => {
            const newOptions = {};
            Object.keys(prevOptions).forEach(key => {
                let updatedIndex = parseInt(key);
                
                if (updatedIndex === oldIndex) {
                    updatedIndex = newIndex;
                } else if (updatedIndex === newIndex) {
                    updatedIndex = oldIndex;
                }
    
                newOptions[updatedIndex] = prevOptions[key];
            });
    
            return newOptions;
        });
    };


    return (
        <>
        <Typography>Deprecated questions are excluded by default</Typography>
        <Typography>Add questions for the survey set</Typography>
        <ArrayInput source="questions" label=''>
            <SimpleFormIterator inline fullWidth reOrderButtons={<ReOrderButtons onReorder={handleReorder} />}>
                <FormDataConsumer>
                    {({ formData, scopedFormData, getSource, ...rest }) => {                  
                        const index = parseInt(getSource('dimension').split('.')[1]);
                        return (
                            <>
                                <SelectInput
                                    key={`${index}-dimension`}
                                    source={getSource('dimension')}
                                    choices={DimensionTypes.en.map(dimension => ({ id: dimension, name: dimension }))}
                                    onChange={(event) => handleDimensionChange(event?.target?.value, index)}
                                    onCreate={() => {
                                        const newDimensionName = prompt('New question dimension');
                                        if(newDimensionName) {
                                            DimensionTypes.en.push(newDimensionName);
                                            return { target: newDimensionName };
                                        }
                                    }}
                                />
                                {((scopedFormData && scopedFormData.dimension) || (options[index] || selectedValues[index])) && (
                                    <SelectInput
                                        key={`${index}-${Math.random()}`}
                                        source={getSource('questionId')}
                                        onChange={(event) => {
                                            const optionsList = options[index] || optionsHistory.get(scopedFormData.dimension)
                                            const selectedOption = optionsList.find(option => option.questionId === event.target.value);
                                            handleQuestionChange(event.target.value, selectedOption.question[selectedLanguage], index, selectedOption.deprecated);
                                            if (selectedOption) {
                                                formData.questions[index].deprecated = selectedOption.deprecated;
                                            }
                                        }}
                                        choices={(options[index] || optionsHistory.get(scopedFormData.dimension) || []).map(option => ({
                                            id: option.questionId,
                                            name: option.question[selectedLanguage]
                                        }))}
                                        disabled={!options[index - 1]}
                                        sx={{ width: '60%' }}
                                        {...rest}
                                    />
                                )}
                                {(formData.questions && formData.questions[index] && formData.questions[index].deprecated) && <TextField value={'Deprecated'} disabled={true} />}
                            </>
                        )
                    }}
                </FormDataConsumer>
            </SimpleFormIterator>
        </ArrayInput>
        </>
    )
}
