import * as React from "react";
import { Edit} from 'react-admin';
import { QuestionForm } from "../../components/questions/QuestionForm";

export const EditQuestion = () => {
    return (
        <Edit>
            <QuestionForm 
                formType={'edit'}
                saveWithConfirm={true} 
                confirmText={'Updating the question text (DE or EN) will affect all surveys in which it is included.\
                If only specific survey must be affected, create new question and update the survey.\
                If only specific client must be affected, create new question and new survey set'}
                />
        </Edit>
    )
}
