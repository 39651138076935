import { useState } from 'react';
import { Select, MenuItem, FormControl, Typography, Grid, Box } from '@mui/material';
import { QuestionsSelector } from '../../components/surveySets/QuestionSelector';
import { TextInput, SimpleForm, required } from 'react-admin';
import { InterimsSelector } from '../../components/surveySets/InterimsSelector';
import { BottomToolbar } from '../../components/BottomToolbar';
import { EditSurveyBottomToolbar } from './EditSurveyToolbar';

export const SurveySetsForm = ({ formType, dataProvider }) => {
    const [selectedLanguage, setSelectedLanguage] = useState(formType === 'edit' ? 'en': undefined)

    const handleSelectLanguage = (event) => {
        setSelectedLanguage(event.target.value);
    }
    return (
        <>
            <FormControl sx={{ width: '30%', marginLeft: '10px', marginBottom: '15px' }}>
                <Typography>{'Select language for questions list'}</Typography>
                <Select
                    value={selectedLanguage || ''}
                    onChange={handleSelectLanguage}
                >
                    <MenuItem value={'en'} key={'q-en-key'}>English</MenuItem>
                    <MenuItem value={'de'} key={'q-de-key'}>Deutsch</MenuItem>
                </Select>
            </FormControl>
            <SimpleForm
                sanitizeEmptyValues
                toolbar={formType === 'create'
                    ? <BottomToolbar form='surveySets' saveWithConfirm={false} confirmText={''} />
                    : <EditSurveyBottomToolbar />}
                >
                <Grid container spacing={1} >
                    <Grid item xs={6}>
                        <Box sx={{ marginBottom: '20px' }}>
                            <Typography>{'Set the scope for the survey set'}</Typography>
                            <TextInput source="scopeId" validate={[required()]} defaultValue="general" helperText='If custom, id of the client' label='' sx={{ width: '50%' }} />
                        </Box>
                    </Grid>
                    <Grid item xs={6}>
                        <Box sx={{ marginBottom: '20px' }}>
                            <Typography>{'Survey Set Name (Optional)'}</Typography>
                            <TextInput source="name" label='' sx={{ width: '60%' }} />
                        </Box>

                    </Grid>
                </Grid>
                {selectedLanguage &&
                    <>
                        <QuestionsSelector dataProvider={dataProvider} selectedLanguage={selectedLanguage} />
                        <InterimsSelector dataProvider={dataProvider} selectedLanguage={selectedLanguage} />
                    </>
                }
            </SimpleForm>
        </>
    )
}