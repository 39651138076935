import { Edit, useDataProvider } from 'react-admin';
import { useParams } from 'react-router-dom';
import { SurveySetsForm } from '../../components/surveySets/SurveySetForm';

export const EditSurveySet = () => {
    const dataProvider = useDataProvider();
    const params = useParams();
    const [setId, scopeId] = params.id.split('#');

    const queryOptions = {
        meta: {
            setId,
            scopeId,
        },
    };

    return (
         <Edit queryOptions={queryOptions}>
            <SurveySetsForm formType={'edit'} dataProvider={dataProvider}/>
        </Edit>
    )
}