import { Create, useDataProvider, useNotify, useRedirect } from 'react-admin';
import './SurveySets.css';
import { SurveySetsForm } from '../../components/surveySets/SurveySetForm';

export const CreateSurveySet = (props) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const redirect = useRedirect();

    const onSuccess = (data) => {
        notify(`New survey set id ${data.id} was created`);
        redirect('list', 'surveySets')
    }


    return (
        <Create {...props} mutationOptions={{ onSuccess }}>
            <SurveySetsForm formType={'create'} dataProvider={dataProvider} />
        </Create>
    );
};
