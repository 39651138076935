import { useState } from 'react';
import {
    Confirm,
    useUpdate,
    SaveButton,
    useRefresh,
    useNotify
} from 'react-admin';
import { useWatch } from 'react-hook-form';

export const SaveWithConfirm = ({form, content}) => {
    const data = useWatch()
    const notify = useNotify();
    const refresh = useRefresh()
    const [open, setOpen] = useState(false);

    const [update, { isLoading }] = useUpdate(
        form,
        { id: data?.id, data },
        {
            onSuccess: (data) => {
                refresh()
                notify('Question successfully updated');
            },
            onError: (error) => {
                notify(`Question update error: ${error}`);
            }
        }
    );

    const handleClick = (e) => {
        e.preventDefault();
        setOpen(true);
    }
    const handleDialogClose = () => setOpen(false);
    const handleConfirm = () => {
        update()
        setOpen(false);
    };

    return (
        <>
            <SaveButton type='button' onClick={handleClick} />
            <Confirm
                isOpen={open}
                loading={isLoading}
                title={`Change for #${data?.id}`}
                content={content}
                onConfirm={handleConfirm}
                onClose={handleDialogClose}
            />
        </>
    );
};