import { useRecordContext, useGetOne } from 'react-admin';
import { Typography, Stack, Divider, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

export const ViewSurveySetsPanel = () => {
    const record = useRecordContext();
    const { data: questionDetails, isLoading, error } = useGetOne('questionsSetsMapping', { id: record.id });

    if (isLoading) { return <div>Loading...</div>; }
    if (error) { return <div>Error: {(error as Error).message}</div>; }
    return (
      <>
        {questionDetails && questionDetails.surveySets && questionDetails.surveySets.length > 0
            ? <>
                <Typography variant='h6' sx={{ fontWeight: 'bold' }}>Question is in survey sets:</Typography>
                <Stack direction="row" divider={<Divider orientation="vertical" flexItem />} spacing={1} flexWrap="wrap" useFlexGap>
                    {questionDetails.surveySets.map(set => (
                        <Item key={set}>{set}</Item>
                    ))}
                </Stack>
            </>
            : <Typography>Question is not used in sets</Typography>}
            </>
    );
};