import { SaveButton, Toolbar, ListButton } from 'react-admin';
import { useNavigate } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { DuplicateButton } from './DuplicateButton'

export const EditSurveyBottomToolbar = () => {
    const navigate = useNavigate();

    return (
        <Toolbar>
            <DuplicateButton resource="surveySets"/>
            <SaveButton />
            <ListButton onClick={() => navigate(-1)} label="Back" icon={<ChevronLeftIcon />} sx={{position: 'absolute', right: '10px'}} />
        </Toolbar>
    )

}