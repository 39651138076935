import { CognitoAuthProvider } from 'ra-auth-cognito';
import { CognitoUserPool } from 'amazon-cognito-identity-js';

const userPool = new CognitoUserPool({
    UserPoolId: process.env.REACT_APP_COGNITO_USERPOOL_ID,
    ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
});

const authProvider = {
    ...CognitoAuthProvider(userPool),
    getUserDetails() {
        const user = userPool.getCurrentUser();
        let idJwt
        user.getSession((err, session) => {
            if (session) {
            idJwt = session.getIdToken().getJwtToken();
            }
    });
    return idJwt
    }
}

export default authProvider