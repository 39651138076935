import * as React from "react"; 
import { useController } from 'react-hook-form';
import { Autocomplete, TextField as MuiTextField } from '@mui/material';

export const CustomAutoComplete = ({ optionsList, name, styleName, ...props }: {optionsList: any[], name: string, styleName?: string}) => {
    const {
        field: { ref, ...inputProps },
    } = useController({
        name,
        defaultValue: null,
    });

    const [currentValue, setCurrentvalue] = React.useState(inputProps.value)


    const handleAutocompleteChange = (_, value) => {
        const selectedOption = optionsList.find((option) => option.label === value);
        let selectedValue;
        if (selectedOption) {
            selectedValue = selectedOption.value
            setCurrentvalue(selectedValue)
            inputProps.onChange(selectedValue);
        } else {
            selectedValue = value
            inputProps.onChange(value);
        }
    };

    return (
        <Autocomplete
            id={name}
            freeSolo
            className={styleName ? styleName : ''}
            onChange={handleAutocompleteChange}
            value={currentValue}
            options={optionsList.map(option => option.label ?? option)}
            renderInput={(params) => <MuiTextField  {...params} {...inputProps} label="Add or select value" />}
        />
    );

};