import {useState, useEffect} from 'react'
import {List, useListController} from 'react-admin'
import {TopToolbarActions} from '../../components/TopToolbar'
import {ShowQuestionDetails} from './ShowQuestionDetails'
import {usePagination} from '../../hooks/usePaginations'
import {Pagination} from '../../components/Pagination'
import {QuestionsDatagrid} from '../../components/questions/QuestionsDatagrid'
import SearchAndFilter from '../../components/questions/SearchAndFilter'

export const QuestionsList = props => {
  const { data } = useListController()
  const [showDialog, setShowDialog] = useState(false)
  const [currentRecord, setRecord] = useState()

  const [searchTerm, setSearchTerm] = useState('')
  const [filteredData, setFilteredData] = useState([])
  const [dimensionFilter, setDimensionFilter] = useState('')
  const [deprecatedFilter, setDeprecatedFilter] = useState<boolean|undefined>()
  const {paginatedData, totalPages, currentPage, handlePageChange} = usePagination(10, filteredData)

  const handleShowDetails = record => {
    setRecord(record)
    setShowDialog(true)
  }

  const handleCloseDialog = () => {
    setShowDialog(false)
  }

  const handleSearch = event => {
    setSearchTerm(event.target.value)
    handlePageChange(1)
  }

  const handleDimensionChange = (_, newValue: string) => {
    setDimensionFilter(newValue)
    handlePageChange(1)
  }

  const handleDeprecatedChange = (_, newValue) => {
    setDeprecatedFilter(newValue?.value)
    handlePageChange(1)
  }

  useEffect(() => {
    if (data) {
      let updatedData = [...data]

      if (searchTerm) {
        updatedData = updatedData.filter(
          item =>
            item.question.de.toLowerCase().includes(searchTerm.toLowerCase()) ||
            item.question.en.toLowerCase().includes(searchTerm.toLowerCase())
        )
      }

      if (dimensionFilter) {
        updatedData = updatedData.filter(item => item.dimTransl?.en === dimensionFilter)
      }

    if (deprecatedFilter !== undefined ) {
        updatedData = updatedData.filter(item =>
            deprecatedFilter === true ? item?.deprecated === true : !item?.deprecated
        );
    }

      setFilteredData(updatedData)
    }
  }, [data, searchTerm, dimensionFilter, deprecatedFilter])

  return (
    <>
      <List {...props} actions={<TopToolbarActions />} pagination={<></>} resource="questions" sort={{}}>
        <SearchAndFilter
          searchTerm={searchTerm}
          dimensionFilter={dimensionFilter}
          deprecatedFilter={deprecatedFilter}
          handleSearch={handleSearch}
          handleDimensionChange={handleDimensionChange}
          handleDeprecatedChange={handleDeprecatedChange}
        />
        <QuestionsDatagrid data={paginatedData} onViewDetails={handleShowDetails} />
        <Pagination currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
      </List>
      {showDialog && <ShowQuestionDetails isOpen={showDialog} onClose={handleCloseDialog} record={currentRecord} />}
    </>
  )
}
