import React from 'react';
import { TextField, Autocomplete, Grid, InputAdornment } from '@mui/material';
import { DeprecatedOptions, DimensionTypes } from '../../constants/questionDetails';
import FilterListIcon from '@mui/icons-material/FilterList';
import { SearchAndFilterProps } from '../../constants/questionTypes';
import { SearchField } from '../SearchField';


const commonTextFieldProps = {
    variant: 'outlined' as 'outlined',
    sx: {
        mb: 2,
        ml: '10px',
        width: 300,
        maxWidth: '100%'
    }
};

const SearchAndFilter: React.FC<SearchAndFilterProps> = ({
    searchTerm,
    dimensionFilter,
    deprecatedFilter,
    handleSearch,
    handleDimensionChange,
    handleDeprecatedChange
}) => (
    <Grid container spacing={2}>
        <Grid item xs={3}>
            <SearchField searchTerm={searchTerm} handleSearch={handleSearch} label={"Search question"}/>
        </Grid>
        <Grid item xs={3}>
            <Autocomplete
                freeSolo
                options={DimensionTypes.en}
                value={dimensionFilter}
                onChange={handleDimensionChange}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Dimension"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FilterListIcon />
                                </InputAdornment>
                            ),
                        }}
                        {...commonTextFieldProps}
                    />
                )}
            />
        </Grid>
        <Grid item xs={3}>
            <Autocomplete
                options={DeprecatedOptions}
                getOptionLabel={(option) => option.label || ''}
                value={DeprecatedOptions.find(option => option.value === deprecatedFilter) || null}
                onChange={handleDeprecatedChange}
                isOptionEqualToValue={(option, value) => option.value === value?.value}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        placeholder="Deprecated"
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                                <InputAdornment position="start">
                                    <FilterListIcon />
                                </InputAdornment>
                            ),
                        }}
                        {...commonTextFieldProps}
                    />
                )}
            />
        </Grid>
    </Grid>
);

export default SearchAndFilter