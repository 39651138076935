import { Datagrid, TextField, EditButton, BooleanField } from 'react-admin';
import { CustomBooleanField } from '../CustomBooleanField';
import { ViewDetailsButton } from './ViewDetailsButton';
import { ViewSurveySetsPanel } from './ViewSurveySetsPanel';
import { BulkActionButtons } from './BulkActionButtons';

export const QuestionsDatagrid = ({ data, onViewDetails }) => (
    <Datagrid
        expand={<ViewSurveySetsPanel />}
        expandSingle
        data={data}
        bulkActionButtons={<BulkActionButtons />}
    >
        <TextField source="id" sortable={false} />
        <TextField source="question.de" aria-multiline sx={{ maxWidth: '20px', flex: '1' }} sortable={false} />
        <TextField source="question.en" aria-multiline sortable={false} />
        <TextField source="dimTransl.en" label="Dimension" sortable={false} />
        <TextField source="aspTransl.en" label="Aspect" sortable={false} />
        <TextField source="scale" sortable={false} />
        <BooleanField source="deprecated" FalseIcon={null} sortable={false} />
        <CustomBooleanField source="backside" />
        <EditButton />
        <ViewDetailsButton onClick={onViewDetails} />
    </Datagrid>
);