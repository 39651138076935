import { useState, useEffect } from 'react';

export const useGetInterims = (dataProvider, selectedLanguage) => {
    const [interims, setInterims] = useState([]);

    useEffect(() => {
        (async function() {
            try {
                const { data } = await dataProvider.getList('questions', { filter: { type: 'interim' } });
                const interimsList = data.map(interim => (
                    {
                        id: interim.id,
                        name: (() => {
                            const description = interim.description?.[selectedLanguage] || "";
                            const title = interim.title?.[selectedLanguage] || "";
                            const content = interim.content?.[selectedLanguage] || "";
                            const main = interim.main?.[selectedLanguage] || "";
                            const contentAfterMain = interim.contentAfterMain?.[selectedLanguage] || "";
                    
                            return `${interim.id} -  
                                ${description || title || (content + ' ' + main + ' ' + contentAfterMain) || ' '}`;
                        })()
                    }   
                    ))
                
                setInterims(interimsList);
            } catch (e) {
                console.error(e);
            }
        })();

    }, [dataProvider, selectedLanguage])

    return interims ;
};